<style scoped>
/* .card-body >>> table > tbody > tr > td {
    cursor: pointer;
}

input[type=texte] {
    width: 100%;
    padding: 12px 20px;
    margin: 4px 0;
    box-sizing: border-box;
    border: none;
    background-color: white;
    color: white;
} */
</style>

<template>
	<errorContainer :error="erreur" :warning="warning">
		<b-row v-if="Tips">
			<!-- <b-col md="12">
				<h1 v-if="!$screen.sm" :class="[`${!$screen.sm ? 'main-page-title' : ''}`, , { 'is-pwa': $isPwa() }]">
					{{ this.FormMSG(10, 'Tips of the day') }}
				</h1>
			</b-col> -->
			<b-col cols="12" xl="12">
				<transition name="slide">
					<div>
						<!-- <h1 v-if="$screen.sm">{{ this.FormMSG(10, 'Tips of the day') }}</h1> -->
						<b-card no-body>
							<!-- bg-variant="secondary" text-variant="white" -->
							<!-- bg-variant="secondary" text-variant="white"  -->
							<b-card-body>
								<b-row>
									<b-col sm="5">
										<b-form-group :label="FormMSG(21, 'Category')" label-for="tipType" v-if="this.Tips.length > 0">
											<b-form-select
												v-model="newTip.category"
												:disabled="false"
												:options="optionsForTipCategory"
												size="md"
												id="tipType"
											></b-form-select>
										</b-form-group>
									</b-col>
									<b-col sm="4">
										<b-form-group v-if="this.Tips.length > 0">
											{{ this.FormMSG(22, 'Published by') }}
											<p>{{ this.lastPublishedOn }}</p>
										</b-form-group>
									</b-col>
									<b-col sm="3">
										<b-button variant="primary" size="sm" @click="addNewTip()" style="margin: 5px">
											<i class="fa fa-lightbulb-o"></i>
											{{ this.FormMSG(33, 'Add new tip of the day') }}
										</b-button>
										<b-button
											variant="primary"
											v-if="this.Tips.length > 0"
											size="sm"
											@click="saveTip()"
											style="margin: 5px"
											:disabled="this.disableSave"
										>
											{{ this.FormMSG(34, 'Save modifications') }}
										</b-button>
										<!-- v-b-modal.newTip  -->
									</b-col>
								</b-row>

								<b-row align-v="stretch">
									<b-col sm="12">
										<b-form-group :label="FormMSG(23, 'Description')" label-for="tipDesc" v-if="this.Tips.length > 0">
											<b-form-textarea
												id="tipDesc"
												:disabled="false"
												:no-resize="true"
												v-model="newTip.description"
												rows="6"
											></b-form-textarea>
										</b-form-group>
									</b-col>
								</b-row>
							</b-card-body>
						</b-card>

						<b-card no-body>
							<b-card-body>
								<b-row>
									<b-col cols="12">
										<h2>{{ this.FormMSG(24, 'Tip of the day list') }}</h2>
									</b-col>
								</b-row>
								<b-row v-if="Tips.length > 0">
									<b-col sm="6" class="mt-2 mb-1">
										<b-form-group label-cols="0" label-align-sm="left" label-size="sm" class="mb-0">
											<b-input-group size="sm">
												<b-form-input
													v-model="filter"
													type="search"
													id="filterInput"
													:placeholder="FormMSG(25, 'Type to Search')"
												></b-form-input>
												<b-input-group-append>
													<b-button :disabled="!filter" @click="filter = ''"><i class="fa fa-times" aria-hidden="true"></i></b-button>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
								</b-row>

								<!--     TABLE OF Departments      use striped to highlight each even row   @row-selected="rowSelected"  :disabled="data.item.department!=0 && data.item.type==0"
                         -->
								<b-row>
									<b-col cols="12" xl="12" v-if="Tips.length > 0">
										<CardListBuilder v-if="$screen.width < 992" :items="Tips" :fields="tipfields" @row-clicked="rowClicked">
											<template slot="actions" slot-scope="data">
												<b-button variant="none" size="sm" @click="delItem(data.item.number)">
													<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
												</b-button>
											</template>
										</CardListBuilder>
										<b-table
											v-if="$screen.width >= 992"
											:hover="hover"
											responsive="sm"
											ref="myTable"
											selectable
											:selectedVariant="selectedColor"
											:select-mode="selectMode"
											:items="Tips"
											:fields="tipfields"
											:current-page="currentPage"
											:filter="filter"
											:per-page="perPage"
											@row-clicked="rowClicked"
											sticky-header="600px"
											bordered
											small
											:head-variant="hv"
										>
											<!-- <template slot="view" slot-scope="data"> -->

											<template v-slot:cell(rem)="data">
												<b-button variant="none" size="sm" @click="delItem(data.item.number)">
													<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
												</b-button>
											</template>
										</b-table>
									</b-col>
									<b-col v-else>
										<div class="empty-state">
											{{ FormMSG(5528, 'Currently you have no tip of the day') }}
										</div>
									</b-col>
									<!--
@show="newTipModal"
@hidden="newTipModal"
-->
									<b-modal
										header-class="header-class-modal-doc-package"
										id="newTip"
										ref="modal"
										:title="this.FormMSG(125, 'New tip of the day')"
										@ok="handleOk"
										@cancel="cancelAddaddNewTip"
										modal-class="mui-animation"
										:fade="false"
									>
										<form ref="form" @submit.stop.prevent="handleSubmit">
											<b-form-group :label="FormMSG(21, 'Category')" label-for="tipType">
												<b-form-select
													v-model="newCategory"
													:disabled="false"
													:options="optionsForTipCategory"
													size="md"
													id="tipType"
												></b-form-select>
											</b-form-group>
											<b-form-group :label="FormMSG(23, 'Description')" label-for="tipDesc">
												<b-form-textarea
													id="tipDesc"
													:disabled="false"
													:no-resize="true"
													v-model="newDescription"
													rows="6"
												></b-form-textarea>
											</b-form-group>
										</form>
									</b-modal>
								</b-row>

								<loading :active.sync="isLoading" :is-full-page="true"></loading>
							</b-card-body>
						</b-card>
					</div>
				</transition>
			</b-col>
		</b-row>
	</errorContainer>
</template>

<script>
// import gql from "graphql-tag";
// import { store } from "@/store";

import Loading from 'vue-loading-overlay';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';

import { GetRandomTipOfDay, AddUpdTipOfDay, DelTipOfDay, GetTipsOfDay } from '@/cruds/tipofday.crud';
import { isNil } from '~utils';

export default {
	name: 'TipOfDay',
	mixins: [languageMessages, globalMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Loading
	},
	data: () => {
		return {
			hv: 'dark',
			erreur: {},
			Tips: [],
			curTip: {},
			newTip: {},
			currentPage: 1,
			currentIdx: 0,
			perPage: 0,
			warning: '',
			filter: '',
			isLoading: false,
			stopPropagation: '',
			nativeEvent: '',
			selectMode: 'single',
			selectedColor: 'primary',
			passwordInput: '',
			passwordState: null,
			useDigElecSign: false,
			newCategory: 0,
			newDescription: '',
			idSelected: -1,
			lastPublishedOn: ''
		};
	},
	computed: {
		optionsForTipCategory() {
			return this.FormMenu(1016);
		},
		disableSave() {
			if (this.newTip.description != this.curTip.description || this.newTip.category != this.curTip.category) {
				return false;
			}
			return true;
		},
		tipfields() {
			return [
				{
					key: 'categoryName',
					label: this.FormMSG(21, 'Category'),
					sortable: true
				},
				{
					key: 'description',
					label: this.FormMSG(23, 'Description'),
					sortable: true
				},
				{
					key: 'fullName',
					label: this.FormMSG(38, 'Published by'),
					sortable: true
				},
				{
					key: 'publishedOn',
					label: this.FormMSG(39, 'Date'),
					formatter: (value) => {
						return value.split('T')[0].substring(8) + '/' + value.split('T')[0].substring(5, 7) + ' ' + value.substring(11, 16);
					},
					sortable: true
				},
				{
					key: 'rem',
					label: this.FormMSG(46, 'Delete'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
		}
	},
	methods: {
		setPublishedDate() {
			var date =
				this.newTip.publishedOn.split('T')[0].substring(8) +
				'/' +
				this.newTip.publishedOn.split('T')[0].substring(5, 7) +
				'/' +
				this.newTip.publishedOn.split('T')[0].substring(0, 4);
			date = date + ' ' + this.newTip.publishedOn.substring(11, 13) + ':' + this.newTip.publishedOn.substring(14, 16);
			return '' + this.newTip.publisher.name + ' ' + this.newTip.publisher.firstName + '    - ' + date;
		},

		async delItem(number) {
			var tipNumber = parseInt(number, 10);
			//  console.log("delitem number:",tipNumber);
			await DelTipOfDay(tipNumber);
			this.reloadData();
		},

		initValuesForNoRecords() {
			//  this.newTip.description = "";
			this.newTip.category = 0;
			this.curTip = this.newTip;
		},

		async reloadData() {
			//console.log("inreloaddata");
			this.Tips = await GetTipsOfDay();
			//      console.log("inreloaddata tips:",this.Tips);
			var curIdx = 1;
			for (var i = 0; i < this.Tips.length; i++) {
				if (this.Tips[i].id == this.idSelected) {
					curIdx = i;
					//    console.log("reload data idx found:",i);
					this.idSelected = -1;
				}
				this.Tips[i].fullName = this.Tips[i].publisher.name + ' ' + this.Tips[i].publisher.firstName;
			}
			// select element
			if (this.Tips.length > 0) {
				// first find if there is a current selection...

				this.curTip = this.Tips[0];
				this.newTip = Object.assign({}, this.curTip);
				//            console.log("reload data idx newtip:",this.newTip);
				this.clickRow(curIdx);
			} else {
				this.initValuesForNoRecords();
			}
		},

		getRowCount(items) {
			//console.log(items)
			return items.length;
		},

		rowClicked(item, index) {
			// index is the index in the currently displayed window
			// copy item content into curTip structure
			//                this.$refs.myTable.clearSelected();
			//      console.log("item in rowClicked  this.$refs.myTable:",  this.$refs.myTable);
			// compute currentIdx
			this.currentIdx = (this.currentPage - 1) * this.perPage + index;

			this.curTip = item;
			this.newTip = Object.assign({}, item);
			this.lastPublishedOn = this.setPublishedDate();
		},

		clickRow(idx) {
			// idx is the index in the Tips array
			this.lastPublishedOn = this.setPublishedDate();
			this.currentPage = 1;

			this.$nextTick(function () {
				// Code that will run only after the
				// entire view has been re-rendered
				// found in https://jsfiddle.net/sem409uc/
				if (!isNil(this.$refs.myTable)) {
					let myTable = this.$refs.myTable.$el,
						tableBody = myTable.getElementsByTagName('tbody')[0],
						tableRows = tableBody.getElementsByTagName('tr');
					tableRows[idx].click();
				}
			});
		},

		addNewTip() {
			this.newCategory = 0;
			this.newDescription = '';
			this.$nextTick(() => {
				this.$bvModal.show('newTip');
			});
		},

		cancelAddaddNewTip() {
			//console.log("in cancel");
		},

		newTipModal() {
			// console.log("in newTipModal")
		},

		async saveTip() {
			//      console.log("in saveTip this.newTip.",this.newTip);

			this.newTip = await AddUpdTipOfDay(this.newTip.number, this.newTip.description, this.newTip.category);
			this.curTip = this.newTip;
			for (var i = 0; i < this.Tips.length; i++) {
				if ((this.Tips[i].number = this.newTip.number)) {
					// tip in the list found
					this.Tips[i] = this.newTip;
					break;
				}
			}
			this.idSelected = this.newTip.id;
			await this.reloadData();
		},
		async handleOk(bvModalEvt) {
			// Prevent modal from closing
			//      console.log("handleOk:",this.newTip.description)
			bvModalEvt.preventDefault();
			// Trigger submit handler
			await this.handleSubmit();
			/*
      this.newTip = await  AddUpdTipOfDay(0,this.newDescription,this.newCategory);
      this.idSelected = this.newTip.id;
      console.log("newtip after adding:",this.newTip);
      this.$nextTick(() => {
      this.$bvModal.hide('newTip')
      });
      */
			await this.reloadData();
		},

		async handleSubmit() {
			// Exit when the form isn't valid
			//console.log("newtip newDescription:",this.newDescription);
			//console.log("newtip newCategory:",this.newCategory);
			this.newTip = await AddUpdTipOfDay(0, this.newDescription, this.newCategory);
			this.idSelected = this.newTip.id;
			//console.log("newtip after adding:",this.newTip);
			this.$nextTick(() => {
				this.$bvModal.hide('newTip');
			});
		}
	},
	created() {
		this.reloadData();
	}
};
</script>
